<template>
  <div class="env">
    <query-header :handle-click-add-btn="handleShowAddEnv" :handle-query="handleRefresh" :handle-refresh="handleRefresh"
                  :query="queryForm" :show-add-btn="userInfo.authArr.includes('DS_ENV_ADD')"
                  :showSelect="false" add-btn-text="创建环境" placeholder="请输入环境名称查询"/>
    <a-table :columns="envColumns" :data-source="envData" size="small">
      <div slot="envName" slot-scope="row">
        <a-button v-if="userInfo.authArr.includes('DS_ENV_UPDATE')" size="small" type="link" @click="handleEdit(row)">
          编辑
        </a-button>
        <a-popconfirm v-if="userInfo.authArr.includes('DS_ENV_DELETE')" cancel-text="取消" ok-text="确认"
                      title="确定删除该环境吗？"
                      @confirm="handleDeleteEnv(row)">
          <a-button type="link">删除</a-button>
        </a-popconfirm>
      </div>
    </a-table>
    <!--    <div class="page-container">-->
    <!--      <a-pagination showSizeChanger :showTotal="()=>{return `共有${envData.length}个环境`}" :total="envData.length"-->
    <!--                    showQuickJumper :pageSizeOptions="['10','20','50','100']" @change="handlePageChange"-->
    <!--                    @showSizeChange="handlePageSizeChange"/>-->
    <!--    </div>-->
    <a-modal
      :title="`${isEdit?'编辑':'创建'}环境`"
      :visible="showAddEnv"
    >
      <div>
        <a-form-model :model="addEnv" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }"
                      label-position="right">
          <a-form-model-item label="环境名称" prop="envName">
            <a-input v-model="addEnv.envName"/>
          </a-form-model-item>
          <a-form-model-item label="备注">
            <a-input v-model="addEnv.description"/>
          </a-form-model-item>
        </a-form-model>
        <div class="footer">
          <a-button type="primary" @click="handleAddEnv">{{ isEdit ? '确认' : '添加' }}</a-button>
          <a-button type="default" @click="handleCancel">取消</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import QueryHeader from '@views/system/components/QueryHeader.vue';
import { mapState } from 'vuex';

export default {
  name: 'Env',
  components: { QueryHeader },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo
    })
  },
  data() {
    return {
      showAddEnv: false,
      isEdit: false,
      showDeleteEnv: false,
      loading: false,
      queryForm: { text: '' },
      selectedEnv: {},
      pageData: {
        size: 20,
        page: 1,
        total: 0
      },
      rules: {
        envName: [
          {
            required: true,
            message: '请填写环境名称',
            trigger: 'blur'
          }
        ]
      },
      addEnv: {
        description: '',
        envName: ''
      },
      envColumns: [
        {
          title: '环境名称',
          dataIndex: 'envName'
        }, {
          title: '备注',
          dataIndex: 'description'
        }, {
          title: '操作',
          fixed: 'right',
          width: 300,
          slots: { title: 'envName' },
          scopedSlots: { customRender: 'envName' }
        }],
      envData: [],
      showEnvData: [],
      envValidate: {
        envName: [
          {
            required: true,
            message: '运行环境名称不能为空',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  mounted() {
    this.handleSearch();
  },
  methods: {
    async listEnv() {
      this.loading = true;
      const data = {
        envName: this.queryForm.text
      };
      const res = await this.$services.listDsEnv({ data });
      if (res.success) {
        this.envData = res.data;
        this.pageData.total = this.envData.length;
        this.handleShowData();
      }
      this.loading = false;
    },
    handleSearch() {
      this.listEnv();
    },
    handleShowAddEnv() {
      this.addEnv = {
        description: '',
        envName: ''
      };
      this.isEdit = false;
      this.showAddEnv = true;
    },
    handleRefresh() {
      this.handleSearch();
    },
    async addEnvFunc() {
      const data = {
        authKinds: [],
        description: this.addEnv.description,
        envName: this.addEnv.envName,
        queryLimit: this.addEnv.queryLimit
      };
      const res = await this.$services.addDsEnv({ data });
      if (res.success) {
        this.handleSearch();
        this.$Message.success('添加成功！');
      }
    },
    async editEnvFunc() {
      const data = {
        authKinds: [],
        description: this.addEnv.description,
        envName: this.addEnv.envName,
        queryLimit: this.addEnv.queryLimit,
        dsEnvId: this.addEnv.id
      };
      const res = await this.$services.updateEnv({ data });
      if (res.success) {
        this.$Message.success('编辑成功！');
        this.handleSearch();
      }
    },
    handleAddEnv() {
      if (this.isEdit) {
        this.editEnvFunc();
      } else {
        this.addEnvFunc();
      }
      this.showAddEnv = false;
    },
    async deleteEnvFunc() {
      const data = {
        dsEnvId: this.selectedEnv.id
      };
      const res = await this.$services.deleteEnv({ data });
      if (res.success) {
        this.$Message.success('删除成功！');
        this.pageData.page = 1;
        this.handleSearch();
      }
    },
    handleDeleteEnv(row) {
      this.selectedEnv = row;
      this.deleteEnvFunc();
      this.showDeleteEnv = false;
    },
    handleCancel() {
      this.showAddEnv = false;
      this.showDeleteEnv = false;
    },
    handleShowData() {
      this.showEnvData = this.envData.slice((this.pageData.page - 1) * this.pageData.size, this.pageData.page * this.pageData.size);
    },
    handlePageChange(page) {
      this.pageData.page = page;
      this.handleShowData();
    },
    handlePageSizeChange(size) {
      this.pageData.size = size;
      this.pageData.page = 1;
      this.handleShowData();
    },
    handleEdit(row) {
      this.isEdit = true;
      this.addEnv = {
        id: row.id,
        description: row.description,
        envName: row.envName
      };
      this.showAddEnv = true;
    }
  }
};
</script>

<style lang="less" scoped>
.env {
  height: 100%;
  position: relative;

  .operation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    .left, .right {
      display: flex;
      align-items: center;
    }

    .left {
      .type-text {
        word-break: keep-all;
        margin-right: 10px;
      }

      .type {
        margin-right: 20px;
      }

      .text {
        margin-right: 10px;
      }
    }

    .right {
      .refresh {
        margin-left: 10px;
      }
    }
  }
}

.add-env-auth-title {
  color: #333333;
  font-family: PingFangSC-Semibold;
  font-weight: 500;
  margin-bottom: 18px;

  .add-env-auth-remark {
    color: @icon-color;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    margin-left: 8px;
  }
}

.add-env-item {
  line-height: 50px;
}

.page-container {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
}
</style>
