<template>
  <div>
    <a-select v-model="filterType" style="width: 100px;margin-right: 20px" @change="handleChangeType">
      <!--      <a-select-option value="type">-->
      <!--        数据源类型-->
      <!--      </a-select-option>-->
      <a-select-option value="env">
        环境
      </a-select-option>
      <a-select-option value="deployType">
        部署类型
      </a-select-option>
      <a-select-option value="name">
        实例名称
      </a-select-option>
    </a-select>
    <a-select v-model="searchKey.dsEnvId" style="width: 150px;margin-right: 8px"
              v-if="filterType==='env'">
      <a-select-option value="">全部</a-select-option>
      <a-select-option v-for="env in envList" :value="env.id" :key="env.id">
        {{ env.envName }}
      </a-select-option>
    </a-select>
    <a-select v-model="searchKey.deployEnvType" style="width: 150px;margin-right: 8px"
              v-if="filterType==='deployType'">
      <a-select-option value="">全部</a-select-option>
      <a-select-option v-for="deploy in deployList" :value="deploy.name" :key="deploy.name">
        {{ deploy.nameI18n }}
      </a-select-option>
    </a-select>
    <a-input v-model="searchKey.dataSourceName" v-if="filterType==='name'" style="width: 150px;margin-right: 8px"
             placeholder="请输入实例名称"/>
    <a-button type="primary" @click="handleSearch(searchKey)">
      查询
    </a-button>
  </div>
</template>
<script>
export default {
  props: {
    handleSearch: Function
  },
  data() {
    return {
      filterData: [],
      envList: [],
      deployList: [],
      filterType: 'env',
      searchKey: {
        dsEnvId: '',
        deployEnvType: '',
        dataSourceType: '',
        dataSourceName: ''
      }
    };
  },
  mounted() {
    this.getEnvList();
  },
  methods: {
    async getEnvList() {
      const res = await this.$services.listDsEnv({ data: {} });

      if (res.success) {
        this.envList = res.data;
      }
    },
    async getDeployEnvList() {
      const res = await this.$services.getDeployEnvList({ data: {} });

      if (res.success) {
        this.deployList = res.data;
      }
    },
    handleChangeType(type) {
      this.searchKey = {
        dsEnvId: '',
        deployEnvType: '',
        dataSourceType: '',
        dataSourceName: ''
      };
      this.filterData = [];
      if (type === 'env') {
        this.getEnvList();
      } else if (type === 'deployType') {
        this.getDeployEnvList();
      }
    }
  }
};
</script>
