<template>
  <div class="rule-container">
    <div class="rule-datasource">
      <div class="filter-datasource">
        <ds-filter :handleSearch="handleSearch"></ds-filter>
      </div>
      <div class="datasource-list">
        <div class="list-title">
          实例名称
        </div>
        <div class="list-body">
          <div @click="handleSelectDataSource(dataSource)"
               :class="`datasource-item ${selectedDataSource.instanceId===dataSource.instanceId?'datasource-item-selected':''}`"
               v-for="dataSource in dataSourceList" :key="dataSource.instanceId">
            <cc-data-source-icon :color="`${selectedDataSource.instanceId===dataSource.instanceId?'$fff':'#4DBAEE'}`"
                                 :size="18" :type="dataSource.dataSourceType"
                                 :instanceType="dataSource.deployEnvType"></cc-data-source-icon>
            {{ dataSource.instanceDesc }}
            <a-button type="link" class="copy-link" @click="handleCopy">复制规则</a-button>
          </div>
        </div>
      </div>
    </div>
    <div class="rule-rules">
      <a-table :pagination="false" :columns="ruleColumns" :data-source="ruleData" size="small">
        <div slot="switch" slot-scope="row">
          <a-switch v-model="row.enable" @change="handleSwitchRule(row)"/>
        </div>
        <div slot="action" slot-scope="row">
          <a-button v-if="Object.keys(row.config).length>0" type="link" block @click="handleEditParam(row)">
            参数配置
          </a-button>
        </div>
      </a-table>
    </div>
    <a-modal
      title="参数配置"
      :visible="showEditParams"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
    >
      <div>
        <div class="rule-title">
          <span class="rule-title-label">规则名称:</span>
          <span class="rule-title-desc">{{selecteddRule.checkerDesc}}</span>
        </div>
        <a-table :pagination="false" :columns="paramColumns" :data-source="paramData" size="small">
          <div slot="paramName" slot-scope="row">
            <div v-for="(config,index) in row.defConfig" :key="index">
              <div v-if="row.paramName===config.name">
                {{config.label}}
              </div>
            </div>
          </div>
          <div slot="value" slot-scope="row">
            <div v-for="(config,index) in row.defConfig" :key="index">
              <div v-if="row.paramName===config.name">
                <a-input v-if="config.type==='input'" style="width:186px"
                         v-model="row.value"></a-input>
                <a-select v-if="config.type==='select'" style="width:186px" v-model="row.value">
                  <a-select-option v-for="option in config.options" :value="option.name" :key="option.name">
                    {{ option.label }}
                  </a-select-option>
                </a-select>
              </div>
            </div>
          </div>
        </a-table>
        <div class="footer">
          <a-button type="primary" @click="confirmEditParam">保存</a-button>
          <a-button @click="handleCancel">取消</a-button>
        </div>
      </div>
    </a-modal>
    <a-modal
      title="复制规则"
      :visible="showCopyRules"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
    >
      <div>
        <a-select mode="multiple" style="width:100%" v-model="selectedTargetDs">
          <a-select-option v-for="dataSource in dataSourceList" :value="dataSource.id"
                           :key="dataSource.instanceId">
            {{ dataSource.instanceDesc }}
          </a-select-option>
        </a-select>
        <div class="footer">
          <a-button type="primary" @click="confirmCopy">保存</a-button>
          <a-button @click="handleCancel">取消</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import DsFilter from '@views/system/rule/DsFilter.vue';
import { SUPPORT_RULE_DATASOURCE_TYPE } from '../../../consts';

export default {
  components: { DsFilter },
  data() {
    return {
      showEditParams: false,
      confirmLoading: false,
      showCopyRules: false,
      selectedDataSource: '',
      selecteddRule: '',
      dataSourceList: [],
      selectedTargetDs: [],
      ruleColumns: [
        {
          title: '规则名称',
          dataIndex: 'checkerDesc'
        },
        {
          title: '停启用',
          slots: { title: 'switch' },
          scopedSlots: { customRender: 'switch' },
          key: 'switch',
          width: 200
        },
        {
          title: '操作',
          slots: { title: 'action' },
          scopedSlots: { customRender: 'action' },
          key: 'action',
          width: 100
        }
      ],
      ruleData: [],
      paramColumns: [
        {
          title: '参数名称',
          slots: { title: 'paramName' },
          scopedSlots: { customRender: 'paramName' }
        },
        {
          title: '参数值',
          slots: { title: 'value' },
          scopedSlots: { customRender: 'value' },
          key: 'value',
          width: 200
        }
      ],
      paramData: []
    };
  },
  mounted() {
    this.getDsList();
  },
  methods: {
    handleSearch(searchKey) {
      this.getDsList(searchKey);
    },
    async getDsList(searchKey) {
      const res = await this.$services.getDsList({ data: { ...searchKey, useVisibility: true } || {} });

      if (res.success) {
        this.dataSourceList = res.data.filter((ds) => SUPPORT_RULE_DATASOURCE_TYPE.includes(ds.dataSourceType));
        this.selectedDataSource = this.dataSourceList[0];
        if (this.selectedDataSource) {
          this.getRuleList();
        } else {
          this.ruleData = [];
        }
      }
    },
    async getRuleList() {
      const res = await this.$services.rulesList({ data: { dataSourceId: this.selectedDataSource.id } });

      if (res.success) {
        this.ruleData = res.data;
      }
    },
    async configStatus(row) {
      const res = await this.$services.configStatus({
        data: {
          dataSourceId: this.selectedDataSource.id,
          checkerName: row.checkerName,
          status: row.enable
        }
      });

      if (res.success) {
        this.getRuleList();
      }
    },
    async configParams(params) {
      const res = await this.$services.configParams({
        data: {
          dataSourceId: this.selectedDataSource.id,
          checkerName: this.selecteddRule.checkerName,
          params
        }
      });

      if (res.success) {
        this.getRuleList();
      }
    },
    async configOverwrite() {
      const res = await this.$services.configOverwrite({
        data: {
          sourceDataSourceId: this.selectedDataSource.id,
          targetDataSourceId: this.selectedTargetDs
        }
      });

      if (res.success) {
        console.log('success');
      }
    },
    handleChangeType() {

    },
    handleSwitchRule(row) {
      this.configStatus(row);
    },
    handleEditParam(row) {
      this.selecteddRule = row;
      this.paramData = [];
      const configKeys = Object.keys(this.selecteddRule.config);
      configKeys.map((item) => {
        this.paramData.push({
          paramName: item,
          value: this.selecteddRule.config[item],
          defConfig: this.selecteddRule.defConfig
        });
        return null;
      });
      this.showEditParams = true;
    },
    confirmEditParam() {
      const params = {};
      this.paramData.map((item) => {
        params[item.paramName] = item.value;
        return null;
      });
      this.showEditParams = false;
      this.configParams(params);
    },
    handleCancel() {
      this.showEditParams = false;
      this.showCopyRules = false;
    },
    confirmCopy() {
      this.showCopyRules = false;
      this.configOverwrite();
    },
    handleCopy() {
      this.showCopyRules = true;
    },
    handleSelectDataSource(dataSource) {
      this.selectedDataSource = dataSource;
      this.getRuleList();
    }
  }
};
</script>
<style lang="less">
.rule-container {
  display: flex;
  height: 100%;

  .rule-datasource {
    width: 360px;
    height: 100%;
    border: 1px solid #D9D9D9;

    .filter-datasource {
      padding: 0 10px;
      border-bottom: 1px solid #D9D9D9;
      vertical-align: middle;
      line-height: 48px;
    }

    .datasource-list {
      .list-title {
        padding: 8px;
        border-bottom: 1px solid #EAEAEA;
        background: #F3F3F3;
      }

      .datasource-item {
        padding: 8px;
        border-bottom: 1px solid #EAEAEA;
        position: relative;
        cursor: pointer;

        &:nth-child(2n) {
          background: #FBFBFB;
        }

        .copy-link {
          position: absolute;
          right: 14px;
          top: 6px;
        }
      }

      .datasource-item-selected {
        background: #0BB9F8 !important;
        color: #ffffff;

        .copy-link {
          color: #ffffff;
        }
      }
    }
  }

  .rule-rules {
    flex: 1;
    border-top: 1px solid #D9D9D9;
    border-right: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;

    .ant-table-small {
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }
}

.rule-title {
  margin-bottom: 16px;
  span {
    font-size: 13px;
  }
  .rule-title-label {
    font-weight: 500;
    margin-right: 6px;
  }
}
</style>
